import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { trashStyles } from "../../context/customStyles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { AddBoxRounded, Delete, Download } from "@mui/icons-material";
import FileField from "../../components/Custom/FileField";
import {
  addCard,
  addCategoryOrDeck,
  deleteCard,
  deleteDeck,
  editCard,
  getAllCategories,
  getAllDecks,
  getDeckById,
  parseCSV,
  updateDeck,
} from "../../endpoints/FlashCards/functions";
import DisplayCards from "../../components/DisplayItems/DisplayCards";
import ModifyCard from "../../components/Modals/ModifyCard";

const ModDeckPage = (props) => {
  const { deckId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [file, setFile] = useState(null);
  const [cards, setCards] = useState([]);
  const [cardModal, setCardModal] = useState("");
  const [deckOptions, setDeckOptions] = useState([]);
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const visibleCards = cards.filter((item) => !item.delete);
  const [changesMade, setChangesMade] = useState({
    deck: false,
    cards: false,
  });
  const [deck, setDeck] = useState({
    id: deckId !== "new" ? deckId : null,
    category: location.state ? location.state.category : 0,
    name: "",
  });

  useEffect(() => {
    async function getCategories() {
      try {
        let result = await getAllCategories(props.token);
        setCategoryOptions(result);
      } catch (error) {
        console.log("Error at getCategories", error);
      }
    }
    async function getDecks() {
      try {
        let result = await getAllDecks(props.token);
        setDeckOptions(result);
      } catch (error) {
        console.log("Error at getDecks", error);
      }
    }
    setLoading(true);
    getCategories();
    getDecks();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (deckId !== "new") {
      async function getDeck() {
        try {
          let result = await getDeckById(props.token, deckId);
          setDeck({
            id: result.deck.id,
            category: result.deck.category.id,
            name: result.deck.name,
          });
          setCards(result.cards);
        } catch (error) {
          console.log("Error at getDeck", error);
        }
      }
      getDeck();
    }
  }, [deckId]);

  function handleRemove() {
    const updatedCards = [...cards];
    if (index !== null && updatedCards[index].id !== undefined) {
      updatedCards[index].delete = true;
    } else {
      updatedCards.splice(index, 1);
    }
    setCards(updatedCards);
    if (changesMade.cards === false) {
      setChangesMade({ ...changesMade, cards: true });
    }
    setCardModal("");
    setIndex(null);
  }

  async function handleDelete() {
    try {
      await deleteDeck(deckId, props.token);
      navigate(-1);
    } catch (error) {
      console.log("Error at handleDelete", error);
    }
  }
  async function parseFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    setChangesMade({ ...changesMade, cards: true });
    try {
      const res = await parseCSV(props.token, formData, deck.id ? deck.id : 0);
      setCards([...cards, ...res]);
    } catch (error) {
      console.log("Error at parseFile", error);
    }
    setFile(null);
  }
  function handleCards(newCards) {
    const newCard = newCards[0];
    const updatedCards = [...cards];
    if (index === null && !newCard.id) {
      newCard.add = true;
      updatedCards.push(newCard);
    } else if (newCard.id) {
      newCard.update = true;
      updatedCards[index] = newCards[0];
    }
    setCards(updatedCards);
    if (changesMade.cards === false) {
      setChangesMade({ ...changesMade, cards: true });
    }
  }

  async function handleSave() {
    let localDeckId = deckId;
    try {
      setSaving(true);
      if (changesMade.deck) {
        if (deckId !== "new") {
          await updateDeck(props.token, deck);
        } else {
          const res = await addCategoryOrDeck(
            "decks",
            props.token,
            deck.name,
            deck.category
          );
          setDeck({ ...deck, id: res.id });
          localDeckId = res.id;
        }
      }
      if (changesMade.cards) {
        const editCards = cards
          .filter((item) => item.update && item.id)
          .map((item) => {
            delete item.update;
            return item;
          });
        const addCards = cards
          .filter((item) => item.add)
          .map((item) => {
            item.deck_id = localDeckId;
            delete item.add;
            return item;
          });

        const removeCards = cards
          .filter((item) => item.delete)
          .map((item) => item.id);

        if (editCards.length > 0) {
          editCards.map(async (item) => {
            await editCard(item.id, props.token, item);
          });
        }
        if (addCards.length > 0) {
          if (deckId === "new") {
            addCards.map((item) => {
              item.deck_id = localDeckId;
              return item;
            });
          }
          try {
            await addCard(addCards, props.token);
          } catch (error) {
            console.log("Error at addCard in ModDeckPage", error);
          }
        }
        if (removeCards.length > 0) {
          for (let i = 0; i < removeCards.length; i++) {
            try {
              await deleteCard(removeCards[i], props.token);
            } catch (error) {
              console.log("Error at deleteCard in ModDeckPage", error);
            }
          }
        }
      }
    } catch (error) {
      console.log("Error at handleSave for updateDeck", error);
    }
    setSaving(false);
    navigate(-1);
  }
  return (
    <div className="page" id="mod-deck-page">
      <NavBar isFlashHome={true} user={props.user} token={props.token} />

      <div className="page-box">
        <Card
          className="card-page"
          sx={{
            boxShadow: "var(--box-shadow)",
            borderRadius: "var(--borderRadius)",
          }}
        >
          <div className="header">
            <h1>{deckId !== "new" ? "Edit Deck" : "New Deck"}</h1>
            {deckId !== "new" && (
              <IconButton
                style={trashStyles}
                color="error"
                onClick={() => setDeleting(true)}
              >
                <Delete fontSize="inherit" color="error" />
              </IconButton>
            )}
          </div>
          <CardContent className="card-content">
            {deleting ? (
              <Alert
                type="error"
                severity="error"
                color="error"
                variant="filled"
                className="card-alert"
                sx={{
                  "& .MuiAlert-action": {
                    padding: 0,
                    gap: 1,
                    alignItems: "center",
                  },
                }}
                action={[
                  <Button
                    color="ghostBlack"
                    variant="contained"
                    disableElevation
                    key="cancel"
                    onClick={() => setDeleting(false)}
                  >
                    Cancel
                  </Button>,
                  <Button
                    color="ghostRed"
                    variant="contained"
                    disableElevation
                    key="delete"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>,
                ]}
              >
                Are you sure you would like to delete this deck?
              </Alert>
            ) : (
              <Alert
                type="info"
                severity="info"
                color="primary"
                className="card-alert"
                variant="filled"
              >
                All changes made to the deck and cards need to be saved below
              </Alert>
            )}

            <div className="rows">
              <div className="content-group">
                <h4 className="italic-subtitle">Deck Details</h4>
                <form className="d-flex gap-3 flex-column">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel color="black" htmlFor="categories-drop">
                      Category
                    </InputLabel>
                    <Select
                      aria-label="Select type: categories or decks"
                      id="categories-drop"
                      label="Category"
                      color="black"
                      value={deck.category}
                      fullWidth
                      onChange={(e) => {
                        setDeck({ ...deck, category: e.target.value });
                        setChangesMade({ ...changesMade, deck: true });
                      }}
                    >
                      <MenuItem value={0}>Choose one...</MenuItem>
                      {categoryOptions &&
                        categoryOptions.map((category) => (
                          <MenuItem
                            key={`category-${category.id}`}
                            value={category.id}
                          >
                            {category.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    color="black"
                    label="Title"
                    inputProps={{ maxLength: 50 }}
                    error={deck && !deck.name && changesMade.deck}
                    required
                    fullWidth
                    value={deck ? deck.name : ""}
                    onChange={(e) => {
                      setDeck({ ...deck, name: e.target.value });
                      setChangesMade({ ...changesMade, deck: true });
                    }}
                  />
                </form>
              </div>
              <hr style={{ margin: 0 }} />
              <div className="content-group">
                <h4 className="italic-subtitle">
                  <div>Bulk Cards Upload</div>
                </h4>
                <div className="container-box d-flex gap-3 flex-column">
                  <Button
                    fullWidth
                    variant="contained"
                    color="darkGray"
                    href="https://docs.google.com/spreadsheets/d/1P_Xs24AJ_tVRLn9XQGHbft1lVT_1qO16ACVWaSqHmVI/export?gid=0&format=csv"
                    download="new_flashcards_upload"
                    disableElevation
                    className="button-link"
                    startIcon={<Download />}
                    sx={{
                      padding: "1rem",
                      height: "3.5rem",
                    }}
                  >
                    CSV Template
                  </Button>
                  <form
                    onSubmit={(e) => {
                      parseFile(file);
                      e.preventDefault();
                    }}
                  >
                    <FileField selectedFile={file} setSelectedFile={setFile} />
                  </form>
                </div>
              </div>
            </div>
            <hr />

            <div className="content-group pb-0" id="cards">
              <h4 className="italic-subtitle">
                <div>Cards</div>
                {visibleCards.length > 0 && !loading && (
                  <IconButton
                    size="large"
                    color="primary"
                    className="p-0"
                    onClick={() => setCardModal("active")}
                  >
                    <AddBoxRounded fontSize="large" />
                  </IconButton>
                )}
              </h4>
              <div className="container-box cards">
                {visibleCards.length === 0 && !loading && (
                  <Button fullWidth onClick={() => setCardModal("active")}>
                    <div className="ghost-section">
                      <h6 className="mb-0">Add Cards</h6>

                      <AddBoxRounded color="primary" className="fab-icon" />
                    </div>
                  </Button>
                )}
                {loading && (
                  <ClipLoader
                    color={`var(--red)`}
                    className="loader"
                    loading={loading}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
                {cards && cards.length > 0 && (
                  <DisplayCards
                    cards={cards}
                    setOpen={setCardModal}
                    setIndex={setIndex}
                  />
                )}
              </div>
            </div>
          </CardContent>
          <CardActions className="float-actions">
            <Button
              href={"/flashcards"}
              size="large"
              color="ghostBlack"
              variant="contained"
              disableElevation
              sx={{ width: "10rem" }}
            >
              cancel
            </Button>
            <Button
              size="large"
              color="black"
              variant="contained"
              disabled={
                !deck.name ||
                !deck.category ||
                saving ||
                (changesMade.deck === false && changesMade.cards === false)
              }
              disableElevation
              onClick={handleSave}
              sx={{ width: "10rem" }}
            >
              {saving ? (
                <ClipLoader
                  color={"#fff"}
                  loading={saving}
                  size={26}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                "Save"
              )}
            </Button>
          </CardActions>
        </Card>
      </div>
      <ModifyCard
        show={cardModal}
        setShow={setCardModal}
        decks={deckOptions}
        deck={deck}
        index={index}
        setIndex={setIndex}
        cards={cards}
        handleSave={handleCards}
        handleRemove={handleRemove}
      />
    </div>
  );
};

export default ModDeckPage;
