import React, { useEffect, useState } from "react";
import "./PlayCards.css";
import "../../DisplayItems/styles/CardView.css";
import Logo from "../../../Logos/logo.png";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { editCard } from "../../../endpoints/FlashCards/functions";
import { Check, Close, Help, HomeRounded } from "@mui/icons-material";

const PlayCards = (props) => {
  const navigate = useNavigate();
  const [flip, setFlip] = useState(false);
  const [open, setOpen] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let card = props.playingCards[props.index];
  const handleClick = (event) => {
    event.stopPropagation();
    setOpen(!open);

    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth < 950) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };
    
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function handleWrong(id) {
    if (!card.in_study_pile) {
      let body = {
        deck_id: card.deck.id,
        question: card.question,
        answer: card.answer,
        hint: card.hint ? card.hint : "",
        in_study_pile: true,
      };
      try {
        await editCard(id, props.token, body);
      } catch (error) {
        console.log("Error occured at handleWrong", error);
      }
    }
    nextQuestion();
  }

  function nextQuestion() {
    if (props.index + 1 < props.playingCards.length) {
      props.setIndex(props.index + 1);
    } else {
      props.setIndex(0);
    }
    setFlip(false);
    setOpen(false);
  }
  return (
    <div id="playing-mode">
      <div className="flash-card" onClick={() => setFlip(!flip)}>
        <div className="background">
          <img
            className="logo-image flash-card-logo"
            src={Logo}
            alt="Assessive Logo"
          />
          {card && card.hint && !flip ? (
            <Tooltip title={card ? card.hint : ""} open={open}>
              <button onClick={handleClick} className="hint">
                <Help
                  sx={{
                    color: open ? "inherit" : "#7d7d7d",
                    fontSize: "1.7rem",
                  }}
                />
              </button>
            </Tooltip>
          ) : null}
        </div>
        {!flip ? (
          <div className="card-front">
            <p>{card ? card.question : ""}</p>
          </div>
        ) : (
          <div className="card-back">
            <h3>{card ? card.answer : ""}</h3>
          </div>
        )}
        <small className="card-count">
          {props.index + 1} of {props.total}
        </small>
      </div>
      <div className="cta-buttons">
        {mobileView ? (
          <>
            <IconButton color="error" onClick={() => handleWrong(card.id)}>
              <Close sx={{ fontSize: "1.7rem" }} />
            </IconButton>
            <IconButton color="black" onClick={() => navigate("/flashcards")}>
              <HomeRounded sx={{ fontSize: "1.7rem" }} />
            </IconButton>
            <IconButton color="success" onClick={() => nextQuestion()}>
              <Check sx={{ fontSize: "1.7rem" }} />
            </IconButton>
          </>
        ) : (
          <>
            <Button
              color="error"
              onClick={() => handleWrong(card.id)}
              startIcon={<Close sx={{ fontSize: "1.7rem" }} />}
            >
              Incorrect
            </Button>
            <Button
              color="black"
              onClick={() => navigate("/flashcards")}
              startIcon={<HomeRounded sx={{ fontSize: "1.7rem" }} />}
            >
              Home
            </Button>
            <Button
              color="success"
              onClick={() => nextQuestion()}
              startIcon={<Check sx={{ fontSize: "1.7rem" }} />}
            >
              Correct
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default PlayCards;
