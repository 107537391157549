import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { properQuestionsCheck } from "../../endpoints/Assessment/functions";
import { IconButton, PopoverPaper, Tooltip } from "@mui/material";
import { CloseRounded, MoreVertRounded } from "@mui/icons-material";
const ExamCard = (props) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    async function disabledButton() {
      let result = await properQuestionsCheck(props.exam.name, props.token);
      if (result === false) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
    disabledButton();
  }, [props.exam]);

  async function copyUrl(e, examName) {
    e.stopPropagation();
    e.preventDefault();
    if (disabled) return;
    const encodedExamName = encodeURIComponent(examName);
    const examUrl = `https://assessive.net/${encodedExamName}/`;
    await navigator.share({
      title: "Assessive",
      text: `Take this exam: ${examName}`,
      url: examUrl,
    });
  }

  function handleClose(e) {
    e.stopPropagation();
    e.preventDefault();
    setShowMenu(false);
  }
  return (
    <Tooltip
      title={disabled ? "Please add questions to this exam." : ""}
      placement="bottom"
      arrow
    >
      <Link
        to={!disabled && !showMenu ? `/${props.exam.name}/` : ""}
        className="exam-flex-box no-decor-link"
      >
        <div className="exam-flex-box">
          <div className="logo-container">
            <img
              src={props.examLogo}
              alt="exam logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = props.errorImage;
              }}
            />
          </div>

          <div className="middle-items">
            <h2>{props.exam.name}</h2>

            <p className="mb-0">{props.exam.description}</p>
          </div>

          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowMenu(!showMenu);
            }}
          >
            {showMenu ? <CloseRounded /> : <MoreVertRounded />}
          </IconButton>
          <PopoverPaper
            square={false}
            sx={{
              position: "absolute",
              right: 10,
              top: 40,
              boxShadow: "var(--box-shadow)",
              zIndex: 100001,
            }}
            className="popover-menu"
            hidden={!showMenu}
            open={showMenu}
            onClose={(e) => handleClose(e)}
            onMouseLeave={(e) => setTimeout(() => handleClose(e), 500)}
          >
            <ul className="menu">
              <li>
                <div
                  className="menu-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(`/exam/${props.exam.id}`);
                  }}
                >
                  Edit Exam
                </div>
              </li>
              <li className="no-border">
                <div
                  className="menu-link"
                  onClick={(e) => copyUrl(e, props.exam.name)}
                >
                  Share Exam
                </div>
              </li>
            </ul>
          </PopoverPaper>
        </div>
      </Link>
    </Tooltip>
  );
};

export default ExamCard;
