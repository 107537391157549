import React, { useState } from "react";
import DisplayExams from "../../components/DisplayItems/DisplayExams";
import NavBar from "../../components/NavBar/NavBar";
import SearchBar from "../../components/Search/SearchBar";
import { getAllExams } from "../../endpoints/Assessment/functions";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
const HomePage = (props) => {
  const [exams, setExams] = useState([]);
  const [allExams, setAllExams] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getExams(unfiltered) {
    if (unfiltered) {
      setLoading(true);
      try {
        let data = await getAllExams(props.token);
        setExams(data);
        setAllExams(data);
      } catch (error) {
        console.log("Error at getExams", error);
      }
      setLoading(false);
    } else setExams(allExams);
  }
  return (
    <div className="page">
      <NavBar isFlashHome={false} user={props.user} token={props.token} />
      <div className="page-box">
        <div>
          <SearchBar
            exams={exams}
            allExams={allExams}
            setExams={setExams}
            getExams={getExams}
            user={props.user}
            token={props.token}
          />
        </div>
        <div>
          {" "}
          <DisplayExams
            loading={loading}
            user={props.user}
            token={props.token}
            getExams={getExams}
            exams={exams}
          />
        </div>{" "}
        <IconButton
          href="/exam/new"
          className="mobile button-link fab"
          color="primary"
          sx={{
            position: "fixed",
            bottom: 2,
            margin: "8px",
            right: 2,
            backgroundColor: "var(--blue) !important",
            width: "56px",
            height: "56px",
          }}
          disableFocusRipple
        >
          {/* <AddCircleRounded color="primary" sx={{ fontSize: 50 }} /> */}
          <Add color="white" sx={{ color: "white !important" }} />
        </IconButton>
      </div>
    </div>
  );
};

export default HomePage;
