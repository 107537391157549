import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider, createTheme } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  palette: {
    primary: {
      main: "#0b4f6c",
      disabled: "#0c0f16",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#8B6E10",
      disabled: "#0c0f16",
      contrastText: "#ffffff",
    },
    error: {
      main: "#8e161f",
      disabled: "#0c0f16",
      contrastText: "#ffffff",
    },
    black: {
      main: "#0c0f16",
      disabled: "#0c0f16",
      contrastText: "#ffffff",
    },
    iconGray: {
      main: "#7d7d7d",
    },
    iconWhite: {
      main: "#ffffff",
      disabled: "#7d7d7d",
    },
    borderGray: {
      main: "#c7c7c7",
    },
    ghostBlue: {
      main: "#c2d3da",
      contrastText: "#0b4f6c",
    },
    ghostBlack: {
      main: "#CDCFD0",
      contrastText: "#0c0f16",
    },
    ghostRed: {
      main: "#f2dede",
      contrastText: "#8e161f",
    },
    ghostWhite: {
      main: "#f8f9fa",
      contrastText: "#0b4f6c",
    },
    darkGray: {
      main: "#404040",
      contrastText: "#ffffff",
    },
  },
  typography: {
    button: {
      textTransform: "capitalize",
      disabled: {
        color: "#0c0f16",
        backgroundColor: "#c7c7c7",
      },
    },
  },
  shape: {
    borderRadius: 5,
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 700,
      md: 950,
      lg: 1280,
      xl: 1920,
    },
  },
});
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
