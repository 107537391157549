import React, { useEffect, useState } from "react";
import Teleport from "../Custom/Teleport";
import "./styles/Modals.css";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import TextAreaField from "../Custom/TextAreaField";
import { trashStyles } from "../../context/customStyles";
import { Delete } from "@mui/icons-material";
import { useParams } from "react-router-dom";

const ModifyCard = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const deck = props.deck ? props.deck.name : queryParams.get("deck");
  const { deckId } = useParams();

  const [cardContent, setCardContent] = useState({
    deck_id: deckId !== "new" ? deckId : 0,
    question: "",
    answer: "",
    hint: "",
    in_study_pile: false,
  });

  function cardInitialValue(decks) {
    const deckArray = deck ? deck.split(",") : [];
    const currentDeck = decks.find((d) => d.name === deckArray[0]);
    return {
      deck_id: currentDeck && deckArray.length === 1 ? currentDeck.id : 0,
      question: "",
      answer: "",
      hint: "",
      in_study_pile: false,
    };
  }

  function toggle() {
    if (props.show === "active") {
      props.setShow("");
      setCardContent({
        ...cardContent,
        deck_id: 0,
        question: "",
        answer: "",
        hint: "",
        in_study_pile: false,
      });
      if (props.index !== null) props.setIndex(null);
    } else {
      props.setShow("active");
      setCardContent(cardInitialValue(props.decks));
    }
  }
  async function saveChanges(card) {
    await props.handleSave([card]);
    toggle();
  }

  function setCardQuestion(question) {
    setCardContent({ ...cardContent, question: question });
  }
  function fillFields() {
    if (props.index !== null && props.cards[props.index]) {
      const card = props.cards[props.index];
      setCardContent({
        id: card.id || null,
        deck_id: card.deck ? card.deck.id : card.deck_id || props.deck.id,
        question: card.question,
        answer: card.answer,
        hint: card.hint,
        in_study_pile: card.in_study_pile,
      });
    } else if (props.show === "active") {
      setCardContent(cardInitialValue(props.decks));
    }
  }

  useEffect(() => {
    fillFields();
  }, [props.index, props.show]);

  return (
    <>
      <Teleport>
        <div className={props.show} id="card-modal">
          <div className="header">
            <h1>{props.index !== null ? "Edit Card" : "Add Card"}</h1>
            {props.index !== null && props.handleRemove && (
              <IconButton
                style={trashStyles}
                color="error"
                onClick={props.handleRemove}
              >
                <Delete fontSize="inherit" color="error" />
              </IconButton>
            )}
          </div>
          <div className="modal-body">
            {!props.deck && (
              <div className="top-items">
                <FormControl sx={{ minWidth: 190, width: "100%" }}>
                  <InputLabel color="black">Deck *</InputLabel>
                  <Select
                    aria-label="select deck"
                    label="Deck *"
                    id="select-deck"
                    required
                    error={false}
                    color="black"
                    value={cardContent.deck_id || 0}
                    onChange={(e) =>
                      setCardContent({
                        ...cardContent,
                        deck_id: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={0}>Select Deck</MenuItem>
                    {props.decks.map((deck) => {
                      return (
                        <MenuItem key={deck.id} value={deck.id}>
                          {deck.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            )}
            <TextAreaField
              label="Question *"
              maxLength={800}
              value={cardContent.question}
              data={cardContent}
              setQuestion={setCardQuestion}
            />
            <TextField
              label="Answer"
              name="answer"
              required
              inputProps={{ maxLength: 100 }}
              sx={{ width: "100%" }}
              color="black"
              value={cardContent.answer}
              onChange={(e) =>
                setCardContent({ ...cardContent, answer: e.target.value })
              }
            />
            <TextField
              name="hint"
              label="Hint (optional)"
              inputProps={{ maxLength: 200 }}
              sx={{ width: "100%" }}
              color="black"
              value={cardContent.hint}
              onChange={(e) =>
                setCardContent({ ...cardContent, hint: e.target.value })
              }
            />
            <FormControlLabel
              className="m-0"
              label="Mark for Review"
              value={cardContent.in_study_pile}
              onChange={(e) => {
                setCardContent({
                  ...cardContent,
                  in_study_pile: e.target.checked,
                });
              }}
              control={
                <Checkbox
                  color="black"
                  size="small"
                  checked={cardContent.in_study_pile}
                />
              }
            />
            <div className="float-actions gap-2 d-flex justify-content-center pt-3">
              <Button
                onClick={toggle}
                color="ghostBlack"
                variant="contained"
                sx={{ maxWidth: "10rem", width: "100%" }}
                disableElevation
              >
                Cancel
              </Button>
              <Button
                color="black"
                className="px-14"
                variant="contained"
                disableElevation
                sx={{ maxWidth: "10rem", width: "100%" }}
                onClick={() => saveChanges(cardContent)}
                disabled={
                  (cardContent.deck_id === 0 && deckId !== "new") ||
                  cardContent.question === "" ||
                  cardContent.answer === ""
                }
              >
                {props.cardIndex !== null ? "Save" : "Add"}
              </Button>
            </div>
          </div>
        </div>
        <div className={props.show} id="card-overlay"></div>
      </Teleport>
    </>
  );
};

export default ModifyCard;
