import { TextField } from "@mui/material";
import React, { useState } from "react";

const Title = ({ text, onEdit, id, isEditing, setEditableItemId }) => {
  const [editedText, setEditedText] = useState(text);

  const handleBlur = async () => {
    await onEdit(id, editedText);
    setEditableItemId(null);
  };

  const handleInputChange = (e) => {
    setEditedText(e.target.value);
  };

  return (
    <div>
      {!isEditing ? (
        <h4 style={{ marginBottom: "0" }}>{editedText}</h4>
      ) : (
        <TextField
          sx={{
            padding: "0",
          }}
          type="text"
          inputProps={{
            maxLength: 50,
          }}
          label="Category Name"
          color="black"
          size="small"
          value={editedText}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDownCapture={(e) => (e.key === "Enter" ? handleBlur() : null)}
          autoFocus
        />
      )}
    </div>
  );
};
export default Title;
