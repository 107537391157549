export const pencilStyles = {
  borderRadius: "var(--borderRadius)",
  backgroundColor: "rgb(139 110 16 / 30%)",
};

export const trashStyles = {
  borderRadius: "var(--borderRadius)",
  backgroundColor: "rgb(142 22 31 / 30%)",
};

export const filterStyles = {
  width: "3rem",
};
