import React from "react";
import "./Custom.css";
const TextAreaField = (props) => {
  return (
    <div className="form__group">
      <label htmlFor="message" className="form__label">
        {props.label}
      </label>
      <textarea
        maxLength={props.maxLength || 275}
        id="message"
        className={!props.error ? "form__field" : "form__field error"}
        placeholder={props.label}
        value={props.value}
        // TODO: FIGURE OUT WHY THIS IS CHANGING AND NOT SAVING
        onChange={(e) => props.setQuestion(e.target.value)}
        rows={4}
      ></textarea>
    </div>
  );
};

export default TextAreaField;
